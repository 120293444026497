
$mobile-max: 599.9px;
$desktop-max: 900px;
$avatar-max-height: 520px;
$avatar-min-height: 180px;

@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Tangerine&display=swap');

body {
  font-family: 'Quicksand', sans-serif;

  @media screen and (max-width: $mobile-max) { 
    font-size: 0.85em;

  }
}
.mapouter{margin: 0 auto;position:relative;text-align:right;height:400px;width:613px;}


h2 {
  text-align: center;
  font-size: 1.3em;
  
}

.page-main {
  line-height: 28px;

  //max-width: $desktop-max;
  margin: 0 auto;


  .top-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 36px;

    .left {
      .logo {
        padding-top: 12px;
        height: 92px;
      }
    }

    .right {

    }
  }


  p {
    margin: 5px 0;
    text-align: justify;
    padding: 0 2em;

    a {
      margin: 0 2px;
      color: #1a7bb1 !important;
      &:hover {
        color: #57a4ce !important;
      }
    }
    svg {
      font-size: 1em;
      vertical-align: middle;
      margin-left: 0px;
      margin-right: 3px;
    }
  }

  .banner {
    width: 100%;
    position: relative; 

    background: white;


    height: calc(100vw / #{$desktop-max / 1px} * #{$avatar-max-height / 1px});
    min-height: $avatar-min-height;
    max-height: $avatar-max-height;

    margin-bottom: 0;

    @media screen and (max-width: $mobile-max) {
      margin-bottom: 0px;
    }

    overflow: hidden;

    .banner-background {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;

      transition: 3.3s opacity ease-out, 16.6s transform linear; 
      transform: scale(1.18);
      opacity: 0;

      
      &[data-active=true]:not([data-reset=true]) {
        opacity: 1;
      }

      &[data-reset=true] {
        transition: none;
        transform: scale(1);
      }

    }
    
    .logo {
      display: none;
      position: absolute;
      width: 88px;
      left: 2em;
      top: 1em;
      img {
        width: 100%;
        filter: drop-shadow(1px 2px 1px #ffffff);
      }

      @media screen and (max-width: $mobile-max) {
        width: 12%;
        left: 6%;
        top: 8%;
      }
    }

    .banner-name {
      position: absolute;
      height: 14%;
      right: 15%;
      top: 25%;
      img {
        height: 100%;
        opacity: 0;
        transition: 3.3s opacity ease-in-out; 

        &[data-active=true] {
          opacity: 1;
        }
      }
    }

    .slogan {
      position: absolute;
      text-align: center;
      color: white;
      bottom: 1em;
      font-size: 3.6em;
      width: 100%;
      text-shadow: 4px 4px 3px #000000;
      font-family: 'Tangerine', cursive;

      @media screen and (max-width: $mobile-max) {
        //font-size: 2.2em;
        bottom: 0.7em;
      }

    }
    .navbar {
      display: none;
      position: absolute;
      right: 0%;
      padding: 5px 18px;

      .icon-button {
        margin-left: 0.2em;
        margin-right: 0.2em;
        @media screen and (max-width: $mobile-max) {
          margin-left: -0.0em;
          margin-right: -0.0em;
        }

        color: #777777;
        transition: 3.0s color;
        &[data-inverted=true] {
          color: #ffffff;
        }
        

        & > .icon {

          font-size: 1.1em;
          // @media screen and (max-width: $mobile-max) {
          //   font-size: 0.8em;
          // }
          
        }
      }
    }
  }

  .pattern-lines {
    
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    max-width: 780px;
    margin: 0.9em auto;
    padding: 0 1em;
    .item {
      // flex-basis: 50%;

      &.img {
        text-align: center;
        padding: 0 1em;
        img {
          width: 100%;
          @media screen and (max-width: $mobile-max) {
            max-width: 420px;
          }
        }
      }

      &.msg {
        text-align: center;
        padding: 1.6em 1.8em;

        @media screen and (max-width: $mobile-max) {
          padding: 0.9em 0.8em;
        }

        .title {
          color: #605691;
          margin-bottom: 0.6em;
          font-size: 1.1em;
          font-weight: bold;
        }

        .content {
          color: #7a7591;
          font-size: 1.0em;
        }
      }
    }

  }

  .promo-video-item {
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .promo-video {
      max-width: $desktop-max;
      margin: 0 auto;
    }
  }

  .slide-container {
    max-width: $desktop-max;
    margin: 3em auto;

    .indicators {
      padding-left: 0.8em;
    }

    .showcase {
      padding: 0px 36px;
      .showcase-img {
        width: 72%;
        margin: 0 auto;
        img {
          width: 100%;
          box-shadow: 4px 3px 12px rgba(30,30,30,0.5);
        }
      }
      .showcase-quote-icon {
  
        color: #813891;
      }
      .showcase-quote {
        font-size: 1.18em;
        line-height: 1.72em;
        margin-top: 0.2em;
        margin-bottom: 1.0em;
        color: #813891;
        text-align: justify;
        text-align-last: center;
      }
      .showcase-name {
        font-size: 1.18em;
        font-weight: bold;
        text-align: center;
      }
      .showcase-title {
        font-size: 0.92em;
        text-align: center;
      }
    }

  }



  .selections {
    text-align: center;

    margin: -18px 5px 16px 5px;
    @media screen and (max-width: $mobile-max) {
      margin-top: 16px;
    }

    a {
      text-decoration: none;
    }
  }

  .main-items {
    margin-top: 0;
    margin-bottom: 14px;

    

    .main-item {
      margin-top: 0;
      margin-bottom: 28px;
      background-position: center; // Center the image 
      background-repeat: no-repeat; // Do not repeat the image 
      background-size: cover; // Resize the background image to cover the entire

      
      &:not(:last-child) {
        margin-bottom: 64px;
        @media screen and (max-width: $mobile-max) {
          margin-bottom: 32px;
        }
      }

      &.promo-slogans {
        padding: 2.6em 0;
        @media screen and (max-width: $mobile-max) {
          padding: 1.6em 0;
        }

        .promo-slogan {
        &.title {
          font-size: 1.4em;
          font-weight: bold;
        }

        color: #692e76;
          text-align: center;
          font-size: 1.3em;
          padding: 1.0em 1em;
          @media screen and (max-width: $mobile-max) {
            // font-size: 1.0em;
            padding: 0.6em 0.7em;
          }
        }
      }

      .texture {
        width: 30%;
        display: inline-block;
        padding: 0 1.533333333%;
        img {
          width: 100%;
        }
      }

      

      a {
        text-decoration: none;
        color: #222222;
      }

      .main-item-img {
        width: 66%;
        margin: 0 auto;

        img {
          width: 100%;
          box-shadow: 4px 3px 12px rgba(30, 30, 30, 0.5);


          transition: 0.3s all;
          &:hover {
            opacity: 0.85;
          }
        }
      }

      .main-item-title {
        padding: 0 2em;
        text-align: center;
        margin-top: 24px;
        font-size: 1.5em;
        @media screen and (max-width: $mobile-max) { 
          margin-top: 16px;
          font-size: 1.0em;
        }
      }

      .main-item-subtitle {
        padding: 0 2em;
        text-align: center;
        font-size: 1.0em;
        @media screen and (max-width: $mobile-max) {
          font-size: 0.7em;
        }
      }

      .main-item-description {
        padding: 0 2em;
        color: #555555;
        text-align: center;
        margin-top: 16px;
        font-size: 1.0em;
        @media screen and (max-width: $mobile-max) { 
          margin-top: 11px;
          font-size: 0.8em;
        }
      }

      .main-item-player {
        display: block;
        margin: 0 auto;
        background: #333333;

        width: 640px;
        height: 360px;
        @media screen and (max-width: 800px) {
          width: 520px;
          height: 293px;
        }
        @media screen and (max-width: 600px) {
          width: 400px;
          height: 225px;
        }
        @media screen and (max-width: 480px) {
          width: 288px;
          height: 162px;
        }

      }
    }
  }

  .hr-top {
    border-top: 1px solid #eaeaea;
  }

  .footer {
    padding: 24px;
    text-align: center;
    color: #bbbbbb;
    font-size: 0.9em;
    margin-bottom: 16px;
    margin-top: 64px;

    & > p {
      text-align: center;
      line-height: 1em;
    }

    @media screen and (max-width: $mobile-max) { 
      margin-bottom: 6px;
      margin-top: 48px;
    }
  }

  .dsp-items {
    margin: 8px 6px 22px 6px;
    .dsp-item {
      
      padding: 16px 8px;
      margin: 16px 0px;
      text-align: center;
      img {
        height: 48px;
        @media screen and (max-width: $mobile-max) { 
          height: 38px;
        }
      }


      transition: 0.3s all;

      &:hover {
        opacity: 0.7;
      }
      

      &:not(:first-child) {
        
      }
    }
  }
}

p.center {
  text-align: center;
  
}